<template>
  <!-- 账款管理 -->
  <div class="page-info-content">
    <Tabs :active-name="formInline.billStat" :tabs-list="tabsList" @getTabName="getTabChange" />
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    >
      <el-form-item label="应收/应付账款到期日" label-width="20px">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDateRange"
        />
      </el-form-item>
    </FormSearch>
    <div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Tabs from '@/components/Tabs.vue'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
import { payalbeBillInfoQueryByPage } from '@/api/tradeFinancing.js'
export default {
  components: { FormSearch, Pagination, Tabs, Table },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        billStat: ''
      },
      tabsList: [
        { label: '全部', name: '' },
        { label: '未确认', name: '01' },
        { label: '已确认', name: '03' }
      ],
      formItemArr: [
        { type: 'input', label: '应收/应付账款内部编码', value: 'billNo' },
        { type: 'input', label: '企业名称(卖方)', value: 'sellCmpName' },
        { type: 'input', label: '企业名称(买方)', value: 'buyCmpName' },
        { type: 'select', label: '数据来源', value: 'dataSource', pLabel: 'dictName', pValue: 'dictId', child: [{ dictName: '系统录入', dictId: 1 }, { dictName: 'ERP', dictId: 2 }] },
        { type: 'select', label: '转让状态', value: 'transStat', pLabel: 'dictName', pValue: 'dictId', child: [{ dictName: '待转让', dictId: '01' }, { dictName: '已转让', dictId: '02' }, { dictName: '未转让', dictId: '03' }, { dictName: '部分转让', dictId: '04' }] },
        { type: 'select', label: '收款/付款状态', value: 'moneyState', pLabel: 'dictName', pValue: 'dictId', child: [{ dictName: '未付款', dictId: '01' }, { dictName: '部分付款', dictId: '02' }, { dictName: '已付款', dictId: '03' }] }
      ],
      itemData: [
        { label: '应收/应付账款内部编码', prop: 'billNo', width: 120 },
        { label: '企业名称(卖方)', prop: 'sellCmpName', width: 280 },
        { label: '企业名称(买方)', prop: 'buyCmpName', width: 280 },
        { label: '应收/应付账款金额', prop: 'billAmt', width: 180 },
        { label: '已收金额', prop: 'receAmt', width: 180 },
        { label: '应收/应付账款余额', prop: 'payableAmt', width: 180 },
        { label: '应收/应付账款到期日', prop: 'acctDate', width: 180 },
        { label: '数据来源', prop: 'dataSource', width: 180, child: [{ dictName: '系统录入', dictId: 1 }, { dictName: 'ERP', dictId: 2 }] },
        { label: '确认状态', prop: 'billStat', child: [{ dictName: '未确认', dictId: '01' }, { dictName: '待确认', dictId: '02' }, { dictName: '已确认', dictId: '03' }], width: 180 },
        { label: '转让状态', prop: 'transStat', child: [{ dictName: '待转让', dictId: '01' }, { dictName: '已转让', dictId: '02' }, { dictName: '未转让', dictId: '03' }, { dictName: '部分转让', dictId: '04' }], width: 180 },
        { label: '收款/付款状态', prop: 'moneyState', child: [{ dictName: '未付款', dictId: '01' }, { dictName: '部分付款', dictId: '02' }, { dictName: '已付款', dictId: '03' }], width: 180 },
        { label: '状态', prop: 'statuse', child: [{ dictName: '进行中', dictId: 0 }, { dictName: '已下架', dictId: 1 }], width: 180 }
      ],
      operationButton: [
        { bType: 'primary', label: '查看详情', handleEvent: this.viewDetails }
      ],
      listData: [],
      total: 0,
      loading: false,
      dateRange: []
    }
  },
  methods: {
    getDate(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1,
          billStat: ''
        }
        this.dateRange = []
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      payalbeBillInfoQueryByPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    getDateRange(val) {
      this.formInline.startDate = val[0]
      this.formInline.endDate = val[1]
    },
    getTabChange(val) {
      if (!val || val === '0') { this.formInline.billStat = '' } else { this.formInline.billStat = val }
      this.getDate(true)
    },
    viewDetails({ id }) {
      this.$router.push({
        path: '/accountsDetails',
        query: { id }
      })
    }
  }
}
</script>

<style scoped>
.page-info-content {
  padding-top: 0;
}
</style>
